const loadImports = async () => {
    if (importPromise) {
        return await importPromise;
    }
    const { createEIP1193Provider } = await import('@web3-onboard/common');
    const importedSDK = await import('@metamask/sdk');
    const MetaMaskSDKConstructor = 
    // @ts-ignore
    importedSDK.MetaMaskSDK || importedSDK.default.MetaMaskSDK;
    if (!MetaMaskSDKConstructor) {
        throw new Error('Error importing and initializing MetaMask SDK');
    }
    return { createEIP1193Provider, MetaMaskSDKConstructor };
};
let importPromise = null;
let sdk = null;
function metamask({ options }) {
    return () => {
        importPromise = loadImports().catch(error => {
            throw error;
        });
        return {
            label: 'MetaMask',
            getIcon: async () => (await import('./icon.js')).default,
            getInterface: async ({ appMetadata }) => {
                var _a, _b;
                sdk = window.mmsdk || sdk; // Prevent conflict with existing mmsdk instances
                if (sdk) {
                    // Prevent re-initializing instance as it causes issues with MetaMask sdk mobile provider.
                    return {
                        provider: sdk.getProvider(),
                        instance: sdk
                    };
                }
                const { name, icon } = appMetadata || {};
                const base64 = window.btoa(icon || '');
                const appLogoUrl = `data:image/svg+xml;base64,${base64}`;
                const imports = await importPromise;
                if (!(imports === null || imports === void 0 ? void 0 : imports.MetaMaskSDKConstructor)) {
                    throw new Error('Error importing and initializing MetaMask SDK');
                }
                const { MetaMaskSDKConstructor } = imports;
                sdk = new MetaMaskSDKConstructor({
                    ...options,
                    dappMetadata: {
                        name: ((_a = options.dappMetadata) === null || _a === void 0 ? void 0 : _a.name) || name || '',
                        url: ((_b = options.dappMetadata) === null || _b === void 0 ? void 0 : _b.url) || window.location.origin,
                        base64Icon: appLogoUrl
                    },
                    _source: 'web3-onboard'
                });
                await sdk.init();
                const provider = sdk.getProvider();
                if (provider) {
                    ;
                    provider.disconnect = () => {
                        sdk === null || sdk === void 0 ? void 0 : sdk.terminate();
                    };
                }
                return {
                    provider,
                    instance: sdk
                };
            }
        };
    };
}
export default metamask;
